.banner {
  width: calc(100vw - 200px);
  height: calc(0.165 * (100vw - 200px));
}

.container-div {
  width: calc(100vw - 200px);
  margin-left: 200px;
  margin-top: 0px;
}

.nav-header {
  height: calc(0.165 * (100vw - 200px));
  border-bottom: 1px solid #f7ef8a;
  width: 100%;
  text-align: center;
  padding: 20px;
  padding-top: 2vw;
  /* display: flex;
  flex-wrap: wrap; */
  background-image: linear-gradient(180deg, #181818, #090909);
}

a:hover {
  text-decoration: none;
}

.slider-card {
  background-color: #ebebeb;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6);
  margin: auto 10px;
  /* border: 2px solid #800000; */
  height: 101px;
  text-align: center;
}

.aprende-btn {
  background-color: #505050;
  padding: 10px 5px;
  min-width: 90px;
  width: 90px;
  height: 40px;
  color: #edd490;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  margin: 7px 5px;
  border: 1px solid #edd490;
  box-shadow: 0px 1px 3px #303030;
}

.aprende-btn:hover {
  background-color: #303030;
}

.aprende-btn-active {
  background-color: #e9c979;
  padding: 10px 5px;
  width: 90px;
  color: black;
  border: 1px solid #edd490;

  min-width: 90px;
  height: 40px;
  box-shadow: 0px 2px 5px #303030;

  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  margin: 7px 5px;
}




.nav-btn {
  background-color: #ebebeb;
  padding: 1px 5px;
  min-width: 90px;
  width: 90px;
  height: 24px;
  color: #800000;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  margin: 5px auto;
  border: 1px solid #800000;
  box-shadow: 0px 1px 3px #303030;
}

.nav-btn:hover {
  background-color: #800000;
  color: white;
}

.bonus {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #111;
  background-color: #606060;
}


.bonus:hover {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #a89665;
  background-color: #505050;
}



@media only screen and (max-width: 1000px) {
  .nav-header {
    display: flex;
    padding-top: 10%;
    justify-content: space-around;
  }
  .avatar {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .banner {
    width: 100vw;
    height: calc(0.165 * (100vw));
  }

  .container-div {
    width: 100vw;
    margin-left: 0px;
    margin-top: 0px;
  }
}
