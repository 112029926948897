@import "~antd/dist/antd.css";

.center {
  text-align: center;
  justify-content: center;
}

.no-m-p {
  padding: 0px;
  margin: 0px;
}

.regular {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: white;
}

.link {
  text-decoration: none;
}

.white {
  color: white;
}

a:hover {
  text-decoration: underline;
}

.p20 {
  padding: 20px;
}

.p10 {
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

.phover:hover {
  color: #808080;
}

.flex {
  display: flex;
}

.montserrat {
  font-family: "Montserrat", Helvetica, sans-serif !important;
  color: rgb(0, 36, 98);
}

h1 {
  font-size: 60px;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  color: rgb(0, 36, 98);
}

h2 {
  font-size: 40px;
  margin: 0px;
  padding: 0px;

  color: rgb(30, 77, 167);
}

.mlarge {
  font-size: 70px;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  color: rgb(0, 36, 98);
}

.mmedium {
  font-size: 50px;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  color: rgb(0, 36, 98);
}

.msmall {
  font-size: 30px;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  color: rgb(0, 36, 98);
}



::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #707070;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/**************************************************** MAIN PAGE ****************************************************/

.main-page {
  max-width: 1280px;
  margin: auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  margin-top: 50px;
}

.logo-top {
  width: 200px;
  margin-top: 0px;
}

.input {
  border-radius: 1px;
  height: 30px;
  outline: none;
  margin: 5px 0px;
  border: 1px solid #bebebe;
  width: 100%;
}

.top-bg-div {
  background: linear-gradient(135deg, #002462 0, #0053b7 100%);
  color: white;
  padding: 5px 10vw 50px 10vw;
}

.question {
  height: 180px;
  margin: 10px;
}

.header-sales {
  display: flex;
  height: 80px;
}

.region-select {
  width: 100%;
  background-color: white;
}

.terms-highlight:hover {
  color: 'blue'
}

.terms-highlight {
  color: 'black'
}
a.terms-highlight {
  color: 'black'
}
.next-step {
  background-color: rgb(0, 36, 98);
  font-weight: bold;
  font-size: x-large;
  padding: 20px;
  outline: none;
  border: none;
  border-radius: 3px;
  color: white;
  width: 250px;
  cursor: pointer;
  margin: 10px auto;
  text-align: center;
}


.freeBtn {
  cursor: pointer;
  padding: 100px;
  
  box-shadow: 0px 8px 12px rgba(0,0,0,0.4);
}

.freeBtn:hover {
  box-shadow: 0px 8px 28px rgba(0,0,0,0.6);

}

@media only screen and (max-width: 600px) {
  .logo-top {
    width: 150px;
    margin-top: 10px;
    margin-bottom: -20px;
  }

  h1 {
    font-size: 40px;
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat", Helvetica, sans-serif !important;
    color: rgb(0, 36, 98);
  }

  h2 {
    font-size: 30px;
    margin: 0px;
    padding: 0px;

    color: rgb(30, 77, 167);
  }

  .mlarge {
    font-size: 50px;
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat", Helvetica, sans-serif !important;
    color: rgb(0, 36, 98);
  }

  .mmedium {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat", Helvetica, sans-serif !important;
    color: rgb(0, 36, 98);
  }

  .msmall {
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat", Helvetica, sans-serif !important;
    color: rgb(0, 36, 98);
  }

  .header-sales {
    text-align: center;
    display: block;
    height: 110px;
  }

  .header-menu {
    margin: auto;
  }
}


@media screen and (min-width: 540px) {
  .menuButtonMobile {
    display: none;
    opacity: 0;
  }
}