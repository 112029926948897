.selected-word {
    background-color: #00688b;
    color: white;
    border-radius: 5px;
    padding: 5px;
    
}

.word {
    background-color: #ebebeb;
    color: black;
    border-radius: 5px;
    padding: 5px;
}